.MuiButton-containedPrimary{
  background-color:  #f05f40!important;

}

.MuiList-padding{
  padding-left: 35% !important;
}
.MuiAppBar-colorPrimary{
  background-color: white !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
  border-color: #f05f40 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a{
  text-decoration: none !important;
  color: black !important;
} 

a:hover {
 
  color: #f05f40 !important;
} 

.Footer{
  margin-top: 166px;
  background-color:whitesmoke !important;

}


.text1{
  text-align: left;
  font: normal normal normal 15px/1.4em 'open sans',sans-serif;
  color: #7F7F7F;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 30px !important;
  font-size: 15px !important;
}

.text2{
  text-align: left;
  font: normal normal normal 15px/1.4em 'open sans',sans-serif;
  color: #7F7F7F;
  font-size: 15px !important;
  margin-top: 23px !important;
  line-height: normal;
  letter-spacing: normal;

}

.Heading{
  font: normal normal bold 25px/1.4em wfont_fdeab1_d52aacd0101e4a368f7a27b29e6c2824,wf_d52aacd0101e4a368f7a27b29,orig_comfortaa_regular;
  color: #262671;
  font-family: 'Karla', sans-serif !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: start;
  margin-top: 10px!important;
}

.text{

  height: 65px;
  color: white;
  font-size: 22px !important;
  /* background-color: aqua; */
  padding: 20px;
}


body{
  overflow-x: hidden !important; 
  background-color: #FFFFFF !important;
}


.MuiList-padding{
  padding-left: 0!important;
  height: auto;

}

.AmazonLogo{
  /* height:75px; */
  text-align: start !important;

}

.Amazon-icon{
  height:70px;
  width:30%;
  margin-left: -16px;
}


.navItem{
  margin-top: 10px !important;
}


.MuiMenu-paper {
  top: 460px!important;
}


@media (max-width: 480px) {
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    margin-top: 140px !important;
  }

  .Amazon-icon{
    height: 76px;
    width: 83%;
    margin-left: -18px;
    background-color: tomato !important;
  }

  .Footer{
    margin-top: 10px;
  }


  .MuiMenu-paper{
    top: 220px!important;

  }
}